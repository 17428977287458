'use client'

import { Dialog } from "@headlessui/react"
import { Incident } from "./AcquiaStatus"
import { DrupalTaxonomyTermProduct } from "@/types"
import classNames from "classnames"
import { Fragment, ReactNode, useState } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"

export const AcquiaStatusMessage = ({ incidents, product, children }: {
  incidents: Incident[]
  product?: DrupalTaxonomyTermProduct,
  children: ReactNode
}) => {

  const [isOpen, setIsOpen] = useState(false)

  if (incidents.length == 0) {
    return <>{children}</>
  }

  const maxImpact = incidents.reduce((p, c) => {
    if (!p) {
      return c
    }
    if (p.impact == "critical") {
      return p
    }
    if (c.impact == "critical") {
      return c
    }
    if (p.impact == "major") {
      return p
    }
    if (c.impact == "major") {
      return c
    }
    if (p.impact == "minor") {
      return p
    }

    return c
  }).impact

  const incidentsByImpact:Record<Incident["impact"], number>  = {
    critical: 0,
    major: 0,
    minor: 0,
    none: 0,
  }

  incidents.forEach(i => incidentsByImpact[i.impact]++)

  const impactKeys = Object.keys(incidentsByImpact).filter(k => incidentsByImpact[k as Incident["impact"]] > 0)
  const lastImpactKey = impactKeys.pop()

  return <div className={classNames(mapImpact(maxImpact), "overflow-hidden")}>
          <div className="container mx-auto py-2 px-4">
            <div className={classNames("flex gap-2 items-center py-2 transition-all max-h-16 duration-300 overflow-hidden")}>
              <p className="m-0">
                {impactKeys.map((k, pos) => (
                  <Fragment key={pos}>{incidentsByImpact[k as Incident["impact"]]} {k == 'none' ? 'other' : k}{impactKeys.length != (pos + 1) ? ', ' : ''}</Fragment>
                ))}
                {impactKeys.length > 0 && <> and </>}
                {incidentsByImpact[lastImpactKey as Incident["impact"]]} {
                  impactKeys.length == 0 && lastImpactKey == 'none' ? '' : 
                  lastImpactKey == 'none' ? 'other' : lastImpactKey
                } incident{incidents.length > 1 ? 's' : ''} detected impacting {product?.name ?? 'Acquia'} services.
              </p>

              <Dialog open={isOpen} onClose={() => setIsOpen(false)} className={'absolute top-0 left-0 w-full z-50'}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 flex w-screen overflow-y-auto items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-6xl rounded bg-white rounded-lg overflow-hidden">
                  <Dialog.Title className={'flex items-center bg-gray-700 text-white font-display text-2xl font-medium p-4'}>
                    <span className="flex-grow">Acquia System Status</span>
                    <button onClick={() => setIsOpen(false)} className=""><span className="sr-only">Close</span><XMarkIcon className="w-8" /></button>
                  </Dialog.Title>
                  <Dialog.Description as='div' className={'relative'}>
                    {children}
                  </Dialog.Description>
                </Dialog.Panel>
                </div>
              </Dialog>

                <button onClick={() => setIsOpen(true)} className={classNames(buttonBgColor(maxImpact), "text-white flex gap-2 p-2 rounded items-center")}>
                    More
                </button>
            </div>
          </div>
        </div>
}

function mapImpact(impact: Incident["impact"]) {
  switch (impact) {
    case 'critical':
      return "bg-orange-300"
    case 'major':
      return "bg-yellow-300"
    case 'minor':
      return "border border-yellow-600 bg-yellow-300/30"
    default: 
      return "border border-teal-600"
  }
}

function buttonBgColor(impact: Incident["impact"]) {
  if (impact == "minor") {
    return "bg-yellow-600"
  }
  if (impact == "major") {
    return "bg-orange-600"
  }
  if (impact == "critical") {
    return "bg-pink-800";
  }
  return "bg-teal-600"
}
